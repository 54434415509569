.about-container {
    padding: 2.5rem 0 5rem 20rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* LEFT SIDE */
.about-left-box {
    padding-left: 5.5rem;
}
.about-left-title:before {
    content: '<h1>';
    position: absolute;
    font-family: cursive;
    color: #515152;
    font-size: 14px;
    font-weight: 200;
    margin-top: -5rem;
    left: -1.25rem;
}
.about-left-title {
    position: relative;
    font-size: 6.75rem;
    line-height: 8.25rem;
}
.about-left-title span {
    display: inline-block;
    transition: color .35s ease;
}
.about-left-title span:hover {
    color: #08fdd8;
}
@keyframes aboutFadeIn {
    from {
        transform: scale3d(1, 1, 1);
        opacity: 0;
    }
    30% {
        transform: scale3d(1.25, 0.75, 1)
    }
    40% {
        transform: scale3d(0.75, 1.2, 1)
    }
    50% {
        transform: scale3d(1.15, 0.85, 1);
        opacity: 1;
    }
    65% {
        transform: scale3d(.95, 1.05, 1)
    }
    75% {
        transform: scale3d(1.05, .95, 1)
    }
    to {
        transform: scale3d(1, 1, 1);
        opacity: 1;
    }
}
#letter_H {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 0s;
}
#letter_i_one {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: .05s;
}
#letter_comma_one {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: .1s;
}
#letter_I {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: .15s;
}
#letter_hyphen {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: .2s;
}
#letter_m {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: .25s;
}
#letter_D_one {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: .3s;
}
#letter_a {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: .35s;
}
#letter_v_one {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: .4s;
}
#letter_i_two {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: .45s;
}
#letter_d {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: .5s;
}
#letter_comma_two {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: .55s;
}
#letter_W {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1s;
}
#letter_e_one {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.05s;
}
#letter_b {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.1s;
}
#letter_D_two {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.25s;
}
#letter_e_two {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.3s;
}
#letter_v_two {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.35s;
}
#letter_e_three {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.4s;
}
#letter_l {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.45s;
}
#letter_o {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.5s;
}
#letter_p {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.55s;
}
#letter_e_four {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.65s;
}
#letter_r {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.7s;
}
#letter_r1 {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.75s;
}
#letter_r2 {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.8s;
}
#letter_r3 {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.85s;
}
#letter_r4 {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.9s;
}
#letter_r5 {
    opacity: 0;
    animation: aboutFadeIn 1s ease-in-out forwards;
    animation-delay: 1.95s;
}
@keyframes aboutTitleAfterFade {
    100% {
        opacity: 1
    }
}
.about-left-title:after {
    content: '</h1>';
    position: absolute;
    font-family: cursive;
    color: #515152;
    font-size: 14px;
    font-weight: 200;
    margin-top: 2.25rem;
    margin-left: 1rem;
    -webkit-animation: aboutTitleAfterFade .5s ease 1.8s forwards;
    -moz-animation: aboutTitleAfterFade .5s ease 1.8s forwards;
    -ms-animation: aboutTitleAfterFade .5s ease 1.8s forwards;
    -o-animation: aboutTitleAfterFade .5s ease 1.8s forwards;
    animation: aboutTitleAfterFade .5s ease 1.8s forwards;
    opacity: 0;
}
@keyframes aboutDescriptionFade {
    100% {
        opacity: 1
    }
}
.about-left-description:before {
    content: '<p>';
    font-family: cursive;
    color: #515152;
    font-size: 14px;
    font-weight: 200;
    position: absolute;
    margin-top: -2.65rem;
    left: -1.25rem;
    animation: aboutDescriptionFade 1s ease .65s forwards;
    opacity: 0;
}
.about-left-description {
    position: relative;
    color: #8d8d8d;
    font-size: 1.6rem;
    letter-spacing: 4px;
    margin-top: 4rem;
    animation: aboutDescriptionFade 1s ease .65s forwards;
    opacity: 0;
}
.about-left-description:after {
    content: '</p>';
    font-size: 14px;
    font-family: cursive;
    color: #515152;
    position: absolute;
    left: -1.25rem;
    bottom: -2.25rem;
    animation: aboutDescriptionFade 1s ease .65s forwards;
    opacity: 0;
}
@keyframes aboutButtonBoxUp {
    100% {
        margin-top: 6.5rem;
        margin-bottom: 0;
        opacity: 1
    }
}
.about-left-button-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 11.5rem;
    margin-bottom: -5rem;
    opacity: 0;
    animation: aboutButtonBoxUp 1s ease 1.25s forwards;
}
.about-contact-button {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 50px;
    letter-spacing: 4px;
    height: 51.5px;
    width: 215px;
    background-color: transparent;
    border: 1px solid #08fdd8;
    color: #08fdd8;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    transition: 0.3s ease-out all;
}
.about-contact-button:hover {
    color: #1f1f1f;
}
.about-contact-button:after {
    background: #08fdd8;
    content: "";
    height: 220px;
    left: -350px;
    position: absolute;
    top: -100px;
    transform: rotate(-45deg);
    transition: all .8s cubic-bezier(0.19, 1, 0.22, 1);
    width: 350px;
    z-index: -10;
}
.about-contact-button:hover:after {
    left: -50px;
}
.about-resume-box {
    margin-left: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.about-resume-title {
    font-size: 2.085rem;
    font-weight: 400;
    font-style: italic;
    color: #fff;
    text-align: center;
}
.about-resume-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}
.about-resume-pdf {
    height: 5.4rem;
    width: 5.4rem;
    margin-top: .2rem;
    transition: all ease .15s;
}
.about-resume-pdf:hover {
    cursor: pointer;
    transform: scale(1.085);
}
.about-resume-pdf:active {
    transform: scale(.95);
}
.about-resume-googleDocs {
    height: 5rem;
    width: 5rem;
    transition: all ease .15s;
}
.about-resume-googleDocs:hover {
    cursor: pointer;
    transform: scale(1.085);
}
.about-resume-googleDocs:active {
    transform: scale(.95);
}

/* RIGHT SIDE */
@keyframes aboutRightSideFade {
    100% {
        opacity: 1
    }
}
.about-right-box {
    padding-right: 6.5rem;
    width: 42.5%;
    animation: aboutRightSideFade 1.5s ease 1.5s forwards;
    opacity: 0;
}
.about-right-title:before {
    content: '<h2>';
    position: absolute;
    font-family: cursive;
    color: #515152;
    font-size: 14px;
    font-weight: 200;
    margin-top: -3.75rem;
    left: -1rem;
}
.about-right-title {
    position: relative;
    color: #08fdd8;
    font-size: 5.25rem;
    line-height: 12.65rem;
    font-weight: 500;
}
.about-right-title:after {
    content: '</h2>';
    position: absolute;
    font-family: cursive;
    color: #515152;
    font-size: 14px;
    font-weight: 200;
    left: -1rem;
    bottom: -3.75rem;
}
.about-right-description:before {
    content: '<p>';
    position: absolute;
    font-family: cursive;
    color: #515152;
    font-size: 14px;
    font-weight: 200;
    margin-top: -2.5rem;
    left: -1rem;
}
.about-right-description {
    margin-top: 2rem;
    position: relative;
    font-size: 1.7rem;
    line-height: 1.5;
}
.about-right-description span {
    text-decoration: underline;
    font-weight: 500;
    color: #08fdd8;
    cursor: pointer;
}
.about-right-description:after {
    content: '</p>';
    position: absolute;
    font-family: cursive;
    color: #515152;
    font-size: 14px;
    font-weight: 200;
    left: -1rem;
    bottom: -2.5rem;
}

/* RESPONSIVE */
@media (max-width: 1400px) {
    .about-left-title {
        font-size: 6rem;
        line-height: 7rem;
    }
}
@media (max-width: 1300px) {
    .about-left-title {
        font-size: 5.5rem;
        line-height: 6.65rem;
    }
    .about-right-title {
        font-size: 5rem;
        line-height: 12rem;
    }
}
@media (max-width: 1250px) {
    .about-container {
        padding: 2.5rem 0 5rem 17rem;
    }
}
@media (max-width: 1200px) {
    .about-left-title {
        font-size: 4.75rem;
        line-height: 6rem;
    }
    .about-right-title {
        font-size: 4.5rem;
        line-height: 11.5rem;
    }
    .about-right-description {
        font-size: 1.55rem;
    }
    .about-contact-button {
        font-size: 14px;
        line-height: 48.5px;
        height: 48.5px;
        width: 180px;
    }
    .about-resume-box {
        gap: 1rem;
    }
    .about-resume-title {
        font-size: 1.85rem;
    }
    .about-resume-pdf {
        height: 4.75rem;
        width: 4.75rem;
    }
    .about-resume-googleDocs {
        height: 4.6rem;
        width: 4.6rem;
    }
    .about-resume-buttons-wrapper {
        gap: .25rem;
    }
    .about-left-description {
        font-size: 1.5rem;
    }
}
@media (max-width: 1100px) {
    .about-container {
        padding: 2.5rem 0 5rem 2rem;
    }
    .about-right-box {
        padding-right: 5rem;
    }
}
@media (max-width: 1000px) {
    .about-container {
        padding: 2.5rem 0 5rem 0;
    }
    .about-right-box {
        padding-right: 3rem;
    }
}
@media (max-width: 950px) {
    .about-left-title {
        font-size: 6rem;
        line-height: 7.25rem;
    }
    .about-right-title {
        font-size: 5rem;
        line-height: 12rem;
    }
    .about-container {
        flex-direction: column;
    }
    .about-left-box {
        padding-left: 0rem;
        margin: 5rem 0;
    }
    .about-right-box {
        padding-right: 0rem;
        width: 40rem;
        margin: 0 0 2.5rem;
    }
    .about-right-description {
        font-size: 1.8rem;
    }
}
@media (max-width: 650px) {
    .about-container {
        min-height: 0;
    }
    .about-left-title {
        font-size: 4.75rem;
        line-height: 6rem;
    }
    .about-left-description {
        font-size: 1.5rem;
    }
    .about-right-title {
        font-size: 4rem;
        line-height: 10rem;
    }
    .about-right-description {
        font-size: 1.4rem;
    }
    .about-contact-button {
        font-size: 12px;
        line-height: 45px;
        height: 45px;
        width: 165px;
    }
    .about-resume-title {
        font-size: 1.65rem;
    }
    .about-resume-pdf {
        height: 4.35rem;
        width: 4.35rem;
    }
    .about-resume-googleDocs {
        height: 4.25rem;
        width: 4.25rem;
    }
    .about-resume-buttons-wrapper {
        gap: 0;
    }
    .about-right-title:before {
        margin-top: -3rem;
    }
    .about-right-title:after {
        bottom: -3.25rem;
    }
}
@media (max-width: 480px) {
    .about-left-title:before {
        margin-top: -3.35rem;
    }
    .about-left-title {
        font-size: 3.5rem;
        line-height: 4.5rem;
    }
    .about-left-title:after {
        margin-top: 1.25rem;
        margin-left: .75rem;
    }
    .about-left-description {
        font-size: 1.35rem;
        letter-spacing: 2px;
    }
    .about-right-box {
        width: 30rem;
    }
    .about-contact-button {
        font-size: 12px;
        line-height: 42px;
        letter-spacing: 2px;
        height: 43px;
        width: 140px;
    }
    .about-resume-box {
        margin-left: 2rem;
        gap: 1rem;
    }
    .about-resume-title {
        font-size: 1.5rem;
    }
    .about-resume-pdf {
        height: 4rem;
        width: 4rem;
        margin-top: .2rem;
    }
    .about-resume-googleDocs {
        height: 3.75rem;
        width: 3.75rem;
    }
}
@media (max-width: 390px) {
    .about-left-title:before {
        margin-top: -3.125rem;
    }
    .about-left-title {
        font-size: 3.75rem;
        line-height: 4.5rem;
    }
    .about-left-title:after {
        margin-top: 1.35rem;
        margin-left: .65rem;
    }
    .about-left-description {
        font-size: 1.25rem;
    }
    .about-contact-button {
        font-size: 12px;
        line-height: 38px;
        height: 39px;
        width: 125px;
    }
    .about-resume-box {
        margin-left: 1.65rem;
        gap: .5rem;
    }
    .about-resume-title {
        font-size: 1.35rem;
    }
    .about-resume-pdf {
        height: 3.65rem;
        width: 3.65rem;
    }
    .about-resume-googleDocs {
        height: 3.4rem;
        width: 3.4rem;
    }
}