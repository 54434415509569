.algo-container {
    padding: 3.5rem 0 3.5rem 18.5rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.algo-header {
    position: relative;
    color: white;
    font-size: 6.25rem;
    font-weight: 600;
    animation: skilsHeadFade 2s ease forwards;
    margin-top: 3.5rem;
    margin-bottom: -3.5rem;
    opacity: 0;
}
.algo-header span {
    display: inline-block;
    transition: color .35s ease;
}
.algo-header span:hover {
    color: #08fdd8;
    animation: contactBounce 1s ease forwards
}
.algo-description {
    margin-top: 2.75rem;
    font-size: 1.85rem;
}
#algo-slide1,
#algo-slide2 {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}
#algo-slide1 {
    opacity: 0;
    animation: showup 2s ease 1.5s forwards;
}
#algo-slide2 {
    width: 0;
    animation: reveal 2.5s ease 1.5s forwards;
}
#algo-child-slide {
    margin-left: -340px;
    animation: slidein 2.5s ease 1.5s forwards;
}
@keyframes showup {
    0% {opacity: 0}
    100% {opacity: 1}
}
@keyframes slidein {
    0% {margin-left: -800px}
    50% {margin-left: -800px}
    100% {margin-left: 0px}
}
@keyframes reveal {
    0% {opacity: 0; width: 0px}
    50% {opacity: 1; width: 0px}
    100% {width: 260px}
}
.algo-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    justify-content: center;
    width: 85%;
    gap: 4.5rem;
    margin-top: 3.85rem;
}
.algo-item {
    padding: 0 1rem;
    width: 54rem;
    margin: 0 auto;
    background-color: #1a1a1a;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    margin-top: 10rem;
    margin-bottom: -10rem;
    opacity: 0;
}
@keyframes algoFadeUp {
    100% {opacity: 1; margin-top: 0; margin-bottom: 0}
}
.algo-item1 {animation: algoFadeUp 1.25s ease .5s forwards}
.algo-item2 {animation: algoFadeUp 1.25s ease .75s forwards}
.algo-item3 {animation: algoFadeUp 1.25s ease 1s forwards}
.algo-item4 {animation: algoFadeUp 1.25s ease 1.25s forwards}
#algoJavascript::before {
    content: "";
    display: block;
    height: 2px;
    margin-left: -1rem;
    width: calc(100% + 2rem);
    transition: all .7s ease;
    background-color: orange
}
#algoRuby::before {
    content: "";
    display: block;
    height: 2px;
    margin-left: -1rem;
    width: calc(100% + 2rem);
    transition: all .7s ease;
    background-color: #FD2155
}
#algoJavascriptRuby::before {
    content: "";
    display: block;
    height: 2px;
    margin-left: -1rem;
    width: calc(100% + 2rem);
    transition: all .7s ease;
    background: linear-gradient(to right,orange, #FD2155);
}
.algo-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.algo-item p:first-child {
    display: block;
    font-size: 16px;
    padding: 1.5rem 2rem 2rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    border: 0;
    margin-top: 1rem;
}
#algoJavascript p:first-child {
    color: orange;
}
#algoRuby p:first-child {
    color: #FD2155;
}
#algoJavascriptRuby p:first-child {
    color: orange;
}
#algoJavascriptRuby p:first-child span:first-of-type {
    color: #aaa;
}
#algoJavascriptRuby p:first-child span:last-of-type {
    color: #FD2155;
}
.algo-item p:last-child {
    display: block;
    font-size: 14px;
    padding: 1.5rem 2rem 2rem;
    color: gray;
    text-transform: uppercase;
    letter-spacing: 3px;
    border: 0;
    margin-top: 1rem;
}
.algo-iframe-wrapper {
    width: 52rem;
    height: 30rem;
    margin-bottom: 1rem;
}
.algo-iframe-wrapper iframe {
    width: 100%;
    height: 100%;
    border-radius: .5rem;
}

/* RESPONSIVE */
@media (max-width: 1400px) {
    .algo-wrapper {
        width: 90%;
        gap: 4rem;
    }
    .algo-item {
        width: 52rem;
    }
    .algo-iframe-wrapper {
        width: 50rem;
        height: 30rem;
    }
}
@media (max-width: 1350px) {
    .algo-wrapper {
        width: 90%;
        gap: 4rem;
    }
    .algo-item {
        width: 48rem;
    }
    .algo-iframe-wrapper {
        width: 46rem;
        height: 28.5rem;
    }
}
@media (max-width: 1250px) {
    .algo-container {
        padding: 3.5rem 0 3.5rem 16.5rem;
    }
    .algo-wrapper {
        width: 95%;
        gap: 3rem;
    }
    .algo-item {
        width: 44rem;
    }
    .algo-iframe-wrapper {
        width: 42rem;
        height: 25.5rem;
    }
}
@media (max-width: 1100px) {
    .algo-header {
        font-size: 5.75rem;
    }
    .algo-container {
        padding: 3.5rem 0 3.5rem 0;
    }
    .algo-wrapper {
        width: 95%;
        gap: 3rem;
    }
    .algo-item {
        width: 40rem;
    }
    .algo-iframe-wrapper {
        width: 38rem;
        height: 21.5rem;
    }
}
@media (max-width: 950px) {
    .algo-header {
        font-size: 5rem;
    }
    .algo-description {
        margin-top: 2.25rem;
        font-size: 1.65rem;
    }
    .algo-container {
        padding: 2rem 0 2rem 0;
    }
    .algo-wrapper {
        width: 95%;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto auto;
        gap: 2rem;
        margin-top: 3rem;
    }
    @keyframes reveal {
        0% {opacity: 0; width: 0px}
        50% {opacity: 1; width: 0px}
        100% {width: 225px}
    }
}
@media (max-width: 650px) {
    .algo-header {
        font-size: 4.25rem;
    }
    .algo-description {
        margin-top: 2.25rem;
        font-size: 1.65rem;
        text-align: center;
        padding: 0 3.25rem;
        height: 4.5rem;
    }
    @keyframes slidein {
        0% {margin-left: 0px}
        50% {margin-left: 0px}
        100% {margin-left: 0px}
    }
    .algo-container {
        padding: 2rem 0 2rem 0;
    }
    .algo-wrapper {
        width: 95%;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto auto;
        gap: 2rem;
        margin-top: 3rem;
    }
    #algo-slide2 {
        opacity: 0; 
        width: 225px;
        animation: reveal 2.5s ease 2.5s forwards;
    }
    @keyframes reveal {
        0% {opacity: 0; width: 225px}
        50% {opacity: 1; width: 225px}
        100% {width: 225px; opacity: 1}
    }
}
@media (max-width: 480px) {

    .algo-item {
        width: 36rem;
    }
    .algo-iframe-wrapper {
        width: 34rem;
        height: 20rem;
    }
    .algo-description {
        font-size: 1.55rem;
        padding: 0;
    }
}