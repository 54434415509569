* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 1px solid red; */
}
body {
  color: #ffffff;
  background-color: #1f1f1f;
}
html {
  font-size: 62.5%;
  font-family: 'Roboto', sans-serif;
}
.swal2-popup {
  font-size: 1.65rem !important;
}