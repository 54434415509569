.modal-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}
#leave-modal {
    position: absolute;
    top: 5rem;
    right: 5rem;
    font-size: 3rem;
    cursor: pointer;
    transition: all .25s ease;
}
#leave-modal:hover {
    transform: rotate(-90deg);
}
.modal-wrapper {
    height: 67.5rem;
    width: 125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-radius: .75rem;
    background-color: #222;
    animation: modalAnim .65s ease forwards;
    margin-right: 75rem;
    opacity: 0;
    gap: 1.5rem;
}
@keyframes modalAnim {
    60% {opacity: 1}
    100% {opacity: 1; margin-right: 0}
}
.modal-video-wrapper {
    width: 42.5%;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    animation: modalFade 1s ease-in-out .85s forwards;
    opacity: 0;
}
@keyframes modalFade {
    100% {opacity: 1}
}
.modal-video-title {
    font-size: 3.5rem;
    font-weight: 800;
    width: fit-content;
    text-align: center;
    line-height: 2.65rem;
    margin-top: 1rem;
}
.modal-video-title span:first-of-type {
    font-size: 1.85rem;
    line-height: 2.75rem;
    color: #949494;
}
.modal-video-title span:last-of-type {
    font-size: 3rem;
    font-weight: 600;
    color: #08fdd8;
}
#modal-trade {
    display: inline-block;
    font-size: 3.25rem;
}
.iframe-wrapper {
    width: 50rem;
}
.iframe-wrapper iframe {
    width: 100%;
    height: 32.5rem;
    border-radius: .5rem;
    margin-bottom: -1rem;
}
.modal-tech-wrapper {
    background-color: #373737;
    width: 50rem;
    padding: 1rem 2rem 1.25rem;
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.modal-tech-wrapper h1 {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 600;
    padding: .2rem 0 .8rem;
    color: #08fdd8;
}
.modal-techs {
    font-size: 1.65rem;
    font-weight: 300;
    line-height: 2.65rem;
    word-spacing: 2px;
    color: #ddd;
    font-style: italic;
}
.modal-techs span {
    font-size: 2rem;
    font-weight: 800;
    font-style: normal;
}
.modal-text-wrapper {
    border-radius: .5rem;
    width: 50%;
    height: 95%;
    margin-left: 2.5%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background-color: #373737;
    animation: modalScale .65s ease-in-out .35s forwards;
    opacity: 0;
    transform: scale(.75);
}
@keyframes modalScale {
    100% {opacity: 1; transform: scale(1)}
}
.modal-header {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.modal-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
}
.modal-title-wrapper img {
    height: 5.25rem;
    width: 5.25rem;
    margin-right: 1.65rem;
}
.modal-title {
    font-size: 4.25rem;
}
.modal-links {
    position: relative;
    padding: .5rem 0 1rem;
    margin-right: 2rem;
}
.modal-link1 {
    font-size: 1.85rem;
    font-weight: 300;
    color: white;
    text-decoration: underline;
}
.modal-link2 {
    position: absolute;
    color: white;
    font-size: 2.65rem;
    transition: all .25s ease;
}
.modal-link2:hover {
    transform: scale(1.125);
}
.modal-tools-wrapper {
    padding: 1.25rem 0 0;
    margin-bottom: -1rem;
}
.modal-tool {
    height: 3.25rem;
    width: 3.25rem;
    margin: 0 .65rem;
    color: #08fdd8;
}
.modal-tool:hover {
    animation: toolBounce 1s ease-in-out;
}
@keyframes toolBounce {
    from {transform: scale3d(1, 1, 1)}
    30% {transform: scale3d(1.25, 0.75, 1)}
    40% {transform: scale3d(0.75, 1.2, 1)}
    50% {transform: scale3d(1.15, 0.85, 1)}
    65% {transform: scale3d(.95, 1.05, 1)}
    75% {transform: scale3d(1.05, .95, 1)}
    to {transform: scale3d(1, 1, 1)}
}
.modal-body {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
    width: 90%;
}
.modal-description {
    font-size: 2rem;
    line-height: 2.75rem;
    height: 38.5%;
    margin-bottom: 1.5rem;
}
.modal-features-header {
    font-size: 2rem;
    font-weight: 500;
    text-shadow: 1.5px 1.5px 2px black;
    line-height: 2rem;
    padding: .65rem 0 .35rem;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
}
.modal-features {
    padding: 1rem 0 2rem;
    border-bottom: 1px solid gray;
    padding-left: 5rem;
}
.modal-feature {
    font-size: 1.75rem;
    line-height: 2.4rem;
    font-weight: 600;
}
.modal-visit-site {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
}
.modal-visit-btn {
    position: relative;	
	font-size: 2rem;
    font-weight: 400;
	letter-spacing: 6px;
	text-transform: uppercase;
    text-decoration: none;
	padding: 1.25rem 4rem;
	color: white;
	transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);	
	cursor: pointer;
}
.modal-visit-btn:before {
    left: 0;
	border: 1px solid white;
	border-left: 0;
	border-right: 0;
}
.modal-visit-btn:after {
    right: 0;
}
.modal-visit-btn:before, 
.modal-visit-btn:after {
	content: '';
	position: absolute;	
	transition: inherit;
	z-index: -1;
    top: 0;
	width: 0;
	height: 100%;
}
.modal-visit-btn:hover {
	color: black;
	transition-delay: .5s;
}
.modal-visit-btn:hover:before {
	transition-delay: 0s;
}
.modal-visit-btn:hover:after {
	background-color: white;
	transition-delay: .35s;
}
.modal-visit-btn:hover:before,
.modal-visit-btn:hover:after {
	width: 100%;
}

/* RESPONSIVE */
@media (max-width: 1450px) {
    #leave-modal {
        top: 3.5rem;
        right: 3.5rem;
    }
    .modal-wrapper {
        padding: 1.5rem;
        width: 105rem;
        height: 65rem;
        gap: 0;
    }
    .modal-text-wrapper {
        width: 52.5%;
        margin-right: 2.25rem;
    }
    .modal-video-wrapper {
        width: 45%;
    }
    .modal-video-title {
        font-size: 3rem;
        margin-top: 2.125rem;
        line-height: 2.6rem;
    }
    .modal-video-title span:first-of-type {
        font-size: 1.7rem;
    }
    .modal-video-title span:last-of-type {
        font-size: 2.65rem;
    }
    .iframe-wrapper {
        width: 40rem;
    }
    .iframe-wrapper iframe {
        height: 26.5rem;
    }
    .modal-tech-wrapper {
        width: 40rem;
        padding: 1.65rem 1.5rem 1.5rem;
    }
    .modal-tech-wrapper h1 {
        font-size: 2rem;
        padding: .2rem 0 .8rem;
    }
    .modal-techs {
        font-size: 1.35rem;
        word-spacing: 0px;
    }
    .modal-techs span {
        font-size: 1.65rem;
    }
    .modal-description {
        font-size: 1.75rem;
        line-height: 2.65rem;
        margin-bottom: 1rem;
    }
}
@media (max-width: 1100px) {
    .modal-container {
        overflow: auto;
        display: block;
        height: 100vh;
    }
    #leave-modal {
        top: 2.5rem;
        right: 2.5rem;
        font-size: 3rem;
        z-index: 9999;
    }
    .modal-wrapper {
        width: 100vw;
        height: 120rem;
        border-radius: 0;
        margin-left: 0;
        justify-content: flex-end;
        flex-direction: column-reverse;
        gap: 2.2rem;
    }
    .modal-text-wrapper {
        margin-top: 2.5rem;
        height: 55.5rem;
        width: 60rem;
        margin-left: 0;
        margin-right: 0;
    }
    .modal-description {
        margin-bottom: 0;
        font-size: 1.7rem;
    }
    .modal-video-wrapper {
        height: 50rem;
        margin-bottom: 2.5rem;
        width: 100%;
    }
    .modal-video-title {
        display: none;
    }
    .iframe-wrapper {
        width: 60rem;
    }
    .iframe-wrapper iframe {
        height: 35rem;
        margin-bottom: 1.5rem;
    }
    .modal-tech-wrapper {
        width: 60rem;
        padding: 1.75rem 2rem 2rem;
    }
    .modal-tech-wrapper h1 {
        font-size: 2.25rem;
        padding: .2rem 0 .8rem;
    }
    .modal-techs {
        font-size: 1.65rem;
        word-spacing: 2px;
    }
    .modal-techs span {
        font-size: 2rem;
    }
}
@media (max-width: 750px) {
    #leave-modal {
        top: 1.5rem;
        right: 1.5rem;
        font-size: 2.5rem;
    }
}
@media (max-width: 650px) {
    .modal-wrapper {
        height: 106.5rem;
        gap: 2rem;
    }
    #leave-modal {
        top: 1.5rem;
        right: 1.5rem;
        font-size: 2.5rem;
    }
    .modal-text-wrapper {
        margin-top: 4rem;
        height: 55rem;
        width: 40rem;
    }
    .modal-video-wrapper {
        height: 40rem;
    }
    .iframe-wrapper {
        width: 40rem;
    }
    .iframe-wrapper iframe {
        height: 27.5rem;
    }
    .modal-tech-wrapper {
        width: 40rem;
    }
    .modal-tech-wrapper h1 {
        font-size: 1.65rem;
    }
    .modal-techs {
        font-size: 1.25rem;
        word-spacing: 1px;
    }
    .modal-techs span {
        font-size: 1.65rem;
    }
    .modal-tool {
        height: 3rem;
        width: 3rem;
    }
    .modal-visit-site {
        height: 8rem;
    }
    .modal-title-wrapper {
        margin-top: 1.75rem;
        line-height: 1.65;
    }
    .modal-title-wrapper img {
        height: 4rem;
        width: 4rem;
        margin-right: 1.25rem;
    }
    .modal-title {
        font-size: 3.125rem;
    }
    .modal-link1 {
        font-size: 1.55rem;
    }
    .modal-link2 {
        font-size: 2.25rem;
    }
    .modal-description {
        font-size: 1.5rem;
        line-height: 2.35rem;
        height: fit-content;
        margin-bottom: 1rem;
    }
    .modal-features-header {
        font-size: 1.65rem;
    }
    .modal-feature {
        font-size: 1.5rem;
        line-height: 2.2rem;
    }
    .modal-visit-btn {
        font-size: 1.5rem;
        letter-spacing: 5px;
        padding: 1.25rem 3.25rem;
    }
}
@media (max-width: 480px) {
    .modal-wrapper {
        height: 105rem;
    }
    #leave-modal {
        top: 1.75rem;
        right: 1.75rem;
    }
    .modal-title-wrapper img {
        height: 3.75rem;
        width: 3.75rem;
        margin-right: 1rem;
    }
    .modal-title {
        font-size: 3rem;
    }
    .modal-text-wrapper {
        height: 55rem;
        width: 36rem;
    }
    .modal-video-wrapper {
        margin-top: 2rem;
        height: 36rem;
    }
    .iframe-wrapper {
        width: 36rem;
    }
    .iframe-wrapper iframe {
        height: 25rem;
    }
    .modal-tech-wrapper {
        width: 36rem;
        padding: 1rem 1.25rem 1.25rem;
    }
    .modal-techs span {
        font-size: 1.6rem;
    }
    .modal-techs {
        font-size: 1.2rem;
        line-height: 2.5rem;
        word-spacing: 0px;
    }
}