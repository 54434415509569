.navbar-toggle-button {
    display: none;
}
.navbar-container {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: #181818;
    height: 100%;
    width: 18.5rem;
    top: 0;
    left: 0;
    z-index: 799;
}
.navbar-top {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 2rem 0;
}
.navbar-top img {
    height: 10rem;
    width: 10rem;
    transition: all .35s ease;
}
.navbar-top img:hover {
    transform: scale(1.065);
    cursor: pointer;
}
.navbar-top h1 {
    margin-top: 1rem;
    font-size: 2.35rem;
    color: white;
}
.navbar-top p {
    margin-top: 1rem;
    font-size: 1.25rem;
    color: #a5a5a5;
    text-align: center;
}
.nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    list-style: none;
    text-align: center;
    height: 100%;
    width: 100%;
}
.nav-link {
    color: #909096;
    text-decoration: none;
}
#first-nav-link {
    border-top: 1px solid #282828;
}
.nav-link h1 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.85;
    border-bottom: 1px solid #282828;
    transition: transform .1s ease;
    cursor: pointer;
}
.nav-link:hover {
    color: #08fdd8;
}
.nav-link:active h1{
    transform: scale(.95);
}
.nav-item {
    position: relative;
    font-size: .75rem;
    margin: 0 1rem;
    line-height: 3.5rem;
    width: 100%;
}
.header-item::after {
    content: "";
    display: block;
    height: .3rem;
    width: 0;
    background-color: transparent;
    transition: all .7s ease;
}
.nav-item::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 0;
    width: .2rem;
    background-color: transparent;
}
.nav-item:hover::after {
    height: 100%;
    background-color: #08fdd8;
}
.nav-item .active {
    color: #08fdd8;
}
.navbar-bottom {
    margin: 2rem 0 5rem;
    display: flex;
    gap: 2rem;
}
.nav-icon {
    font-size: 2.5rem;
    color: #909096;
    transition: all .25s ease;
}
.nav-icon:hover {
    color: #08fdd8;
    transform: scale(1.085);
}

/* RESPONSIVE */
@media (max-width: 1250px) {
    .navbar-container {
        width: 16.5rem;
    }
    .navbar-top img {
        height: 9rem;
        width: 9rem;
    }
    .navbar-top h1 {
        margin-top: 1.125rem;
        font-size: 2.05rem;
    }
}
@media (max-width: 1100px) {
    .navbar-toggle-button {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 1.25rem;
        right: 1.25rem;
        width: 6rem;
        height: 5.5rem;
        background-color: rgba(0,0,0,0.35);
        border-radius: 3px;
        z-index: 899;
        cursor: pointer;
    }
    .navbar-toggle-button .navbar-line {
        height: 4px;
        width: 67.5%;
        margin: 4px 0;
        border-radius: 1px;
        background-color: white;
    }
    .navbar-line {
        transition: all .35s ease-in-out;
    }
    .navbar-line:nth-child(1).navActive {
        transform: translate(0, 12.5px) rotate(45deg);
        background-color: white;
        height: 5px;
        border-radius: 2px;
    }
    .navbar-line:nth-child(2).navActive {
        opacity: 0;
    }
    .navbar-line:nth-child(3).navActive {
        transform: translate(0, -12.5px) rotate(-45deg);
        background-color: white;
        height: 5px;
        border-radius: 2px;
    }
    .navbar-container {
        display: none;
        opacity: 0;
        width: 100%;
    }
    .navbar-container.navActive {
        display: flex;
        animation: navFadeBack .25s ease forwards;
    }
    @keyframes navFadeBack {
        100% {opacity: 1}
    }
    .navbar-top img {
        height: 11rem;
        width: 11rem;
    }
    .navbar-top h1 {
        margin-top: 1rem;
        font-size: 2.5rem;
    }
    .navbar-top p {
        margin-top: .9rem;
        font-size: 1.35rem;
    }
    .nav-item:hover::after {
        background-color: transparent;
    }
}
@media (max-width: 480px) {
    .navbar-top img {
        height: 10rem;
        width: 10rem;
    }
    .navbar-toggle-button {
        top: 1rem;
        right: 1rem;
        width: 5rem;
        height: 4.5rem;
        border-radius: 2px;
    }
    .navbar-toggle-button .navbar-line {
        height: 3.25px;
        margin: 3.5px 0;
    }
    .navbar-line:nth-child(1).navActive {
        transform: translate(0, 10px) rotate(45deg);
        height: 4px;
    }
    .navbar-line:nth-child(3).navActive {
        transform: translate(0, -11px) rotate(-45deg);
        height: 4px;
    }
}