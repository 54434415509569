.work-container {
    padding: 3.5rem 0 3.5rem 18.5rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow-x: hidden;
}
.port-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}
@keyframes portFade {
    100% {opacity: 1}
}
.port-header {
    position: relative;
    color: white;
    font-size: 6.25rem;
    font-weight: 600;
    animation: skilsHeadFade 2s ease forwards;
    margin-top: 3.5rem;
    margin-bottom: -3.5rem;
    opacity: 0;
}
.port-header span {
    display: inline-block;
    transition: color .35s ease;
}
.port-header span:hover {
    color: #08fdd8;
    animation: contactBounce 1s ease forwards
}
.port-description {
    margin-top: 2.75rem;
    font-size: 1.85rem;
    transform: scale(0.9);
    animation: portDescScale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes portDescScale {
    100% {transform: scale(1)}
}
.port-description span {
    display: inline-block;
    opacity: 0;
    filter: blur(2px);
    padding-right: 1px;
} 
.port-description span:nth-child(1) {animation: descFadeIn 0.8s .7s forwards cubic-bezier(0.11, 0, 0.5, 0)}
.port-description span:nth-child(2) {animation: descFadeIn 0.8s .8s forwards cubic-bezier(0.11, 0, 0.5, 0)}
.port-description span:nth-child(3) {animation: descFadeIn 0.8s .9s forwards cubic-bezier(0.11, 0, 0.5, 0)}
.port-description span:nth-child(4) {animation: descFadeIn 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0)}
.port-description span:nth-child(5) {animation: descFadeIn 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0)}
.port-description span:nth-child(6) {animation: descFadeIn 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0)}
.port-description span:nth-child(7) {animation: descFadeIn 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0)}
.port-description span:nth-child(8) {animation: descFadeIn 0.8s 2.3s forwards cubic-bezier(0.11, 0, 0.5, 0); color: #08fdd8; text-decoration: underline; cursor: pointer}
.port-description span:nth-child(9) {animation: descFadeIn 0.8s 2.4s forwards cubic-bezier(0.11, 0, 0.5, 0)}
.port-description span:nth-child(10) {animation: descFadeIn 0.8s 2.5s forwards cubic-bezier(0.11, 0, 0.5, 0)}
.port-description span:nth-child(11) {animation: descFadeIn 0.8s 2.6s forwards cubic-bezier(0.11, 0, 0.5, 0)}
.port-description span:nth-child(12) {animation: descFadeIn 0.8s 2.7s forwards cubic-bezier(0.11, 0, 0.5, 0)}
.port-description span:nth-child(13) {animation: descFadeIn 0.8s 2.8s forwards cubic-bezier(0.11, 0, 0.5, 0)}
@keyframes descFadeIn {
    100% {opacity: 1; filter: blur(0)}
}

#port-contact-slide span {
    color: #08fdd8;
    text-decoration: underline;
    cursor: pointer;
}
/* PORTFOLIO */
.port-items {
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto;
    width: 90%;
    margin-top: 3.85rem;
    gap: 3rem;
    animation: portFade 3s ease forwards;
    opacity: 0;
}
.port-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 27.5rem;
    width: 50%;
    margin: 0 auto;
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    overflow: hidden;
    border: 2px solid transparent;
    transition: border .5s ease-in-out;
}
.port-item:hover {
    border: 2px solid #08fdd8a0;
}
.port-gif-box {
    position: relative;
    height: 27.5rem;
    width: 0;
    border-radius: .5rem 0 0 .5rem;
}
.port-gif-wrapper {
    position: relative;
    display: flex;
    height: 27.5rem;
    width: 0;
    opacity: 0;
}
.port-gif {
    position: absolute;
    right: 0;
    height: 100%;
    width: 100%;
    border-radius: .5rem 0 0 .5rem;
    transition: opacity .5s ease-in-out;
}
.port-gif-box:hover .portGifPopup {
    bottom: 0;
}
#portItem1 {animation: portAnimItem 1s ease 1s forwards}
#portItem1 .port-gif-box {animation: portAnimBox 1s ease 1s forwards}
#portItem1 .port-gif-wrapper {animation: portAnimWrap 1s ease 1s forwards}
#portItem1 .portGifPopup {animation: portAnimPop 1s ease 3s forwards}
#portItem2 {animation: portAnimItem 1s ease 1.5s forwards}
#portItem2 .port-gif-box {animation: portAnimBox 1s ease 1.5s forwards}
#portItem2 .port-gif-wrapper {animation: portAnimWrap 1s ease 1.5s forwards}
#portItem2 .portGifPopup {animation: portAnimPop 1s ease 3.5s forwards}
#portItem3 {animation: portAnimItem 1s ease 2s forwards}
#portItem3 .port-gif-box {animation: portAnimBox 1s ease 2s forwards}
#portItem3 .port-gif-wrapper {animation: portAnimWrap 1s ease 2s forwards}
#portItem3 .portGifPopup {animation: portAnimPop 1s ease 4s forwards}
#portItem4 {animation: portAnimItem 1s ease 2.5s forwards}
#portItem4 .port-gif-box {animation: portAnimBox 1s ease 2.5s forwards}
#portItem4 .port-gif-wrapper {animation: portAnimWrap 1s ease 2.5s forwards}
#portItem4 .portGifPopup {animation: portAnimPop 1s ease 4.5s forwards}

@keyframes portAnimItem {
    60% {transform: scale(.9)}
    100% {width: 100%; transform: scale(1)}
}
@keyframes portAnimBox {
    100% {width: 48.5rem}
}
@keyframes portAnimWrap {
    80% {opacity: 0}
    100% {width: 48.5rem; opacity: 1}
}
@keyframes portAnimPop {
    100% {opacity: 1}
}
.portGifPopup {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    bottom: -6rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    padding: 1.25rem 0;
    color: white;
    background-color: rgba(0, 0, 0, .8);
    transition: all .25s ease-in-out;
    cursor: pointer;
    opacity: 0;
}
.portGifPopup p {
    font-size: 2.25rem;
    font-weight: 200;
    -webkit-text-stroke: 1px white;
}
#goToSitePort {
    font-size: 1.85rem;
    color: white;
}
.port-text-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-color: rgba(0, 0, 0, .35);
    height: 100%;
    width: 100%;
    padding: 0 1rem;
    border-radius: 0 .5rem .5rem 0;
}
.port-title {
    font-size: 4rem;
    font-weight: 400;
}
.port-title span {
    font-size: 3rem;
    padding-left: 2px;
}
.port-title-site {
    color: #08fdd8;
    font-size: 1.65rem;
    padding-top: .75rem;
}
.port-headline {
    font-size: 1.85rem;
    font-weight: 500;
    color: #888;
    line-height: 1.5;
    padding: 2rem;
}
.port-btn-box {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}
.port-btn-more {
    position: relative;
    display: block;
    width: 20rem;
    height: 4.5rem;
    font-size: 1.85rem;
    color: white;
    background-color: black;
    outline: 1px solid #08fdd8;
    border: none;
    border-radius: .5rem;
    cursor: pointer;
    overflow: hidden;
    transition: all .2s ease;
}
.port-btn-more:hover {
    background-color: rgba(255, 255, 255, .05);
}
.port-btn-more:hover > #port-more-text {
    left: -125%;
    transform: translate(125%, -50%);
}
.port-btn-more:hover > #port-more-arrow {
    left: 50%;
    transform: translate(-50%, -50%) scale(1.25);
    -webkit-transform: translate(-50%, -50%) scale(1.25);
}
#port-more-text {
    position: absolute;
    width: fit-content;
    top: 50%;
    left: 30%;
    transform: translate(-30%, -50%);
    transition: all .2s ease;
}
#port-more-arrow {
    position: absolute;
    font-size: 2.65rem;
    width: fit-content;
    top: 50%;
    left: 78.5%;
    transform: translate(-82.5%, -50%);
    -webkit-transform: translate(-51%, -50%);
    transition: all .2s ease;
}

/* OTHER WORK */
.other-separator {
    width: 0;
    height: 1px;
    box-shadow: rgba(15, 15, 15, .65) 0px 2px 4px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255), rgba(255, 255, 255, 0));
    opacity: .8;
    border-radius: 1rem;
    margin: 6.5rem 0 3.5rem;
}
.other-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}
.other-header {
    position: relative;
    color: white;
    font-size: 6rem;
    font-weight: 600;
    margin-top: 5rem;
    margin-bottom: -5rem;
    opacity: 0;
}
.other-header span {
    display: inline-block;
    transition: color .35s ease;
}
.other-header span:hover {
    color: #08fdd8;
    animation: contactBounce 1s ease forwards
}
.other-description {
    margin-top: 2.75rem;
    font-size: 1.85rem;
    opacity: 0;
}
#otherDesc-active {
    animation: portFade 2s ease 1s forwards;
}
.other-items {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: auto auto auto auto;
    justify-content: space-between;
    margin-top: 3.85rem;
    gap: 1rem 2rem;
}
#other-show-more {
    font-size: 1.65rem;
    color: #747474;
    text-decoration: underline;
    cursor: pointer;
}
.ending-work-this-wrapper {
    margin-top: 5rem;
}
.ending-work-this {
    position: relative;
    font-family: 'Lato', sans-serif;
    font-size: 1.65rem;
    font-weight: 300;
    line-height: 1.4;
    color: #bbb;
}
.ending-work-this span:first-of-type {
    position: absolute;
    top: -1rem;
    left: -2rem;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 3.5rem;
    text-align: center;
    text-justify: auto;
    color: #08fdd8;
    font-size: 2.5rem;
    font-weight: 400;
    animation: flickerNote 2s ease infinite;
}
.ending-work-this span:last-of-type a {
    color: #08fdd8;
    text-decoration: underline;
    cursor: pointer;
}
@media (max-width: 1400px) {
    .other-items {
        grid-template-rows: auto auto auto auto;
        grid-template-columns: auto auto auto;
    }
}
@media (max-width: 1250px) {
    .work-container {
        padding: 3.5rem 0 3.5rem 15rem;
    }
    .port-items {
        width: 93.5%;
        margin-right: -1rem;
    }
    .port-title {
        font-size: 3.5rem;
    }
}
@media (max-width: 1100px) {
    .work-container {
        padding: 3.5rem 0 3.5rem 0;
    }
}
@media (max-width: 950px) {
    .work-container {
        padding: 2rem 0 2rem 0;
    }
    .other-items {
        grid-template-rows: auto auto auto auto auto;
        grid-template-columns: auto auto;
    }
    .port-title {
        font-size: 3.25rem;
    }
    .port-header {
        font-size: 5.25rem;
    }
    .other-header {
        font-size: 5rem;
    }
    .port-items {
        width: 95%;
        gap: 2.5rem;
    }
    .port-item {
        flex-direction: column;
        height: 40rem;
        width: 100%;
        background-color: rgba(0, 0, 0, .35);
    }
    @keyframes portAnimBox {
        100% {width: 35rem}
    }
    @keyframes portAnimWrap {
        80% {opacity: 0}
        100% {width: 35rem; opacity: 1}
    }
    .portGifPopup {
        display: none;
    }
    .port-headline {
        font-size: 1.75rem;
        line-height: 1;
        padding: 1.5rem 0;
    }
    .port-title-site {
        padding-top: .25rem;
    }
    .port-description {
        padding: 0 2rem;
        line-height: 1.35;
        text-align: center;
    }
    .port-text-box {
        height: 100%;
        width: 100%;
        padding: 0 1rem;
        background-color: transparent;
    }
    .port-gif-box {
        height: 20rem;
    }
    .port-gif-wrapper {
        height: 20rem;
    }
    .port-gif {
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 1 !important;
    }
    .port-gif-logo {
        display: none;
    }
}
@media (max-width: 800px) {
    .ending-work-this-wrapper {
        padding: 0 2.5rem;
        margin-top: 3.5rem;
        text-align: center;
    }
    .ending-work-this span:first-of-type {
        left: -1.75rem;
    }
}
@media (max-width: 700px) {
    .other-description {
        margin-top: 2rem;
        text-align: center;
        padding: 0 1rem;
    }
    .other-items {
        grid-template-rows: auto auto auto auto auto auto;
        grid-template-columns: auto;
        margin-top: 3rem;
    }
}
@media (max-width: 480px) {
    .port-header {
        font-size: 4.5rem;
    }
    .other-header {
        font-size: 4.35rem;
    }
    .port-description {
        font-size: 1.5rem;
        padding: 0 2rem;
        margin-top: 2rem;
    }
    .other-description {
        font-size: 1.65rem;
    }
    .port-wrapper {
        margin-left: -.75rem;
    }
    .port-items {
        margin-top: 2.5rem;
    }
    .port-item {
        height: 42.5rem;
    }
    @keyframes portAnimItem {
        60% {transform: scale(.9)}
        100% {width: 35rem; transform: scale(1)}
    }
    .port-title {
        font-size: 3rem;
    }
    .port-headline {
        font-size: 1.65rem;
        line-height: 1.2;
    }
    .ending-work-this span:first-of-type {
        display: none;
    }
    .port-btn-more {
        width: 18rem;
        padding: 0;
    }
    #port-more-text {
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #port-more-arrow {
        display: none;
    }
    .port-btn-more:hover > #port-more-text {
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
@media (max-width: 360px) {
    .port-wrapper {
        margin-left: -2rem;
    }
}